import { Button, Card } from "@blueprintjs/core";
import React from "react";

import { ERoutes } from "../../AppRouter";
import Page from "../../components/Page";
import Stack from "../../components/Stack";
import { useCommonHooks } from "../../hooks/useCommonHooks";
import { useRouter } from "../../hooks/useRouter";
import { ETLCodes } from "../../locales";
import { IconNames } from "@blueprintjs/icons";

export interface UnauthorizedPageProps {}

export const UnauthorizedPage: React.FC<UnauthorizedPageProps> = () => {
  const { t, isLoggedIn, user, logout } = useCommonHooks();
  const { push } = useRouter();

  React.useEffect(() => {
    if (isLoggedIn && user?.presenceApp) {
      push(ERoutes.periode);
    }
  }, [isLoggedIn, push, user?.presenceApp]);

  return (
    <Page title={t(ETLCodes.Unauthorized)}>
      <Stack gap={"1rem"}>
        <Card>
          <div style={{ textAlign: "center" }}>
            {t(ETLCodes.UnauthorizedMessage_1)}
          </div>
          <div style={{ textAlign: "center" }}>
            {t(ETLCodes.UnauthorizedMessage_2)}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <Button
              text={t(ETLCodes.Logout)}
              onClick={() => logout()}
              intent="primary"
              icon={IconNames.LOG_OUT}
            />
          </div>
        </Card>
      </Stack>
    </Page>
  );
};

export default UnauthorizedPage;
